html,
body{
    overflow: hidden;
}

.webgl{
    position: absolute;
    z-index: 9;
    width: 200px; 
    height: 200px;
    max-width: 1500px;
    max-height: 1500px;
    border-radius: 4px;
    box-sizing: border-box;
}

@font-face {
    font-family: Geist-Medium;
    src: url("./fonts/Geist-Medium.otf") format("opentype");
}

body {
    margin: 0;
    font-family: Geist-Medium;
    src: url("./fonts/Geist-Medium.otf") format("opentype");
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    z-index: -100;
}
  
h1{
    color: rgb(33, 32, 28);
    font-size: 32px;
    font-weight: 106;
    padding-bottom: 8px;
    font-style: normal;
}
  
h2{
    color:rgba(99, 99, 94, .5);
    font-size: 16px;
    padding-bottom: 8px;
    font-weight: 106;
    font-style: normal;
}
  
h3{
    color: rgba(99, 99, 94, .5);
    font-size: 16px;
    padding-bottom: 8px;
    font-weight: 106;
    font-style: normal;
  
}
  
p{
    color: rgba(99, 99, 94, .5);
    font-size: 16px;
    padding-bottom: 8px;
    line-height: 1.3;
    font-weight: 106;
    font-style: normal;
    margin: 0px;
}
  
  
a{
    color: rgb(119, 85, 204);
    text-decoration: none;
    font-weight: 106;
    font-style: normal;
}
  
a:hover{
    color:rgb(119, 85, 204);
    text-decoration: underline;
    font-weight: 106;
}

#cell-automata{
    color: #989890;
    font-style: normal;
    padding-top: 8px;
}

#main{
    z-index: 10;
    width: 100%;
    background-color: #FDFDFC;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 60px;
    flex-wrap: nowrap;
    position: absolute;
    box-sizing: border-box;
}

  
#threejs-container {
    z-index: 15;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding-top: 16px;
    box-sizing: border-box;
}

@media (max-width: 600px) {
    body {
        font-size: 16px;
    }
    h1{
        font-size: 16px;
    }
    h2{
        font-size: 12px;
    }
    h3, p, a{
        font-size: 12px;
    }
    #main{
        padding: 20px;
    }
  }